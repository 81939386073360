import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    SC.Widget(this.element).bind(SC.Widget.Events.PLAY, () => {
      this.incrementSongAttribute("plays")
    })
    
    SC.Widget(this.element).bind(SC.Widget.Events.CLICK_DOWNLOAD, () => {
      this.incrementSongAttribute("downloads")
    })
  }
  
  incrementSongAttribute(attribute) {
    fetch(`${window.location.href}/increment_${attribute}`, {method: "POST"})
  }
}