import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["download"]
  
  connect() {
    this.element.addEventListener("play", () => {
      this.incrementSongAttribute("plays")
    })
    
    this.downloadTarget.addEventListener("click", () => {
      this.incrementSongAttribute("downloads")
    })
  }
  
  incrementSongAttribute(attribute) {
    fetch(`${window.location.href}/increment_${attribute}`, {method: "POST"})
  }
}